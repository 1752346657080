import type {
	CartIdentifierInput,
	CartLineItemCreateInput,
	CartLineItemsAddMutation as CartLineItemsAddMutationType,
	CartLineItemsAddMutationVariables,
	Exact,
	InputMaybe,
	StoreContextInput,
} from "@evolve-storefront/types";
import {
	type CartFragment,
	type Maybe,
	graphql,
} from "@evolve-storefront/types";
import {
	type GqlResponse,
	useClientGqlFetcher,
} from "@labdigital/graphql-fetcher";
import {
	type UseMutationResult,
	useMutation,
	useQueryClient,
} from "@tanstack/react-query";
import { useStoreContext } from "../useStoreContext";

const CartLineItemsAddMutation = graphql(/* GraphQL */ `
	mutation CartLineItemsAdd(
		$storeContext: StoreContextInput!
		$id: CartIdentifierInput
		$lineItems: [CartLineItemCreateInput!]!
	) {
		cartLineItemsAdd(
			storeContext: $storeContext
			id: $id
			lineItems: $lineItems
		) {
			...Cart
		}
	}
`);

/**
 * Handles all the logic to add line items to the cart with GraphQL
 * @returns
 */
export const useAddLineItems = (): UseMutationResult<
	GqlResponse<CartLineItemsAddMutationType>,
	Error,
	Pick<
		Exact<{
			storeContext: StoreContextInput;
			id?: InputMaybe<CartIdentifierInput>;
			lineItems: Array<CartLineItemCreateInput> | CartLineItemCreateInput;
		}>,
		"id" | "lineItems"
	>
> => {
	const client = useQueryClient();
	const storeContext = useStoreContext();
	const gqlClientFetch = useClientGqlFetcher();
	const cartKey = ["cart"];

	/**
	 * Most GraphQL mutations return the updated cart, so we update the cache manually
	 * instead of invalidating and refetching the cart after every update
	 */
	const setCartCache = (cart: Maybe<CartFragment>) => {
		client.setQueryData(cartKey, cart ?? null);
	};

	const addLineItemsMutation = useMutation({
		mutationFn: ({
			id,
			lineItems,
		}: Pick<CartLineItemsAddMutationVariables, "id" | "lineItems">) =>
			gqlClientFetch(CartLineItemsAddMutation, {
				storeContext,
				id,
				lineItems,
			}),
		onSuccess: async (response) => {
			await client.invalidateQueries({ queryKey: cartKey });
			// Update the quantity indicator if available
			if (
				response.data?.cartLineItemsAdd?.lineItems?.some(
					(item) => item.quantity,
				)
			) {
				client.setQueryData(["cart", "quantity"], {
					data: { cart: response.data.cartLineItemsAdd },
				});
			} else {
				await client.invalidateQueries({ queryKey: ["cart", "quantity"] });
			}

			setCartCache(response.data?.cartLineItemsAdd ?? null);
		},
		// TODO: Optimistically update the cart with the new line item for better UX
		onMutate: async (_change) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await client.cancelQueries({ queryKey: cartKey });
			// Snapshot the previous value
			const previousCart: CartFragment | undefined =
				client.getQueryData(cartKey);

			return { previousCart };
		},
		onError: (_err, _newCart, context) => {
			// Because we use optimistic updates we need to revert the changes when the mutation fails
			setCartCache(context?.previousCart ?? null);
		},
	});

	return addLineItemsMutation;
};
