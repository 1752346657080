"use client";

import NextLink from "next/link";
import type { ReactNode } from "react";
import type { ComponentProps } from "react";
import { useState } from "react";
import { Link as IntlLink } from "~/lib/i18n/navigation";

export type LinkProps = Omit<ComponentProps<typeof IntlLink>, "href"> & {
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- We allow this for now to use intellisense
	href: string | ComponentProps<typeof IntlLink>["href"];
	newTab?: boolean;
	"data-testid"?: string;
};

export const Link = function Link({
	ref,
	newTab,
	href,
	...rest
}: LinkProps): ReactNode {
	const [prefetch, setPrefetch] = useState(false);

	let prefetchTimeout: NodeJS.Timeout | null = null;
	const prefetchArgs = {
		onMouseEnter: () => {
			prefetchTimeout = setTimeout(() => {
				setPrefetch(true);
			}, 150);
		},
		onMouseLeave: () => {
			if (prefetchTimeout) {
				clearTimeout(prefetchTimeout);
				prefetchTimeout = null;
			}
			setPrefetch(false);
		},
		onTouchStart: () => setPrefetch(true),
		onTouchEnd: () => setPrefetch(false),
	};

	if (typeof href === "string") {
		const isExternal = /^https?:/.test(href);

		return (
			<NextLink
				href={href}
				{...rest}
				{...prefetchArgs}
				prefetch={prefetch}
				target={newTab || isExternal ? "_blank" : undefined}
				rel={newTab || isExternal ? "noreferrer" : undefined}
				ref={ref}
			/>
		);
	}

	// We can't prefetch when a locale is defined, so forcefully skip that
	if (Object.hasOwn(rest, "locale")) {
		return <IntlLink href={href} ref={ref} {...rest} />;
	}

	return (
		<IntlLink
			href={href}
			ref={ref}
			prefetch={prefetch}
			{...prefetchArgs}
			{...rest}
		/>
	);
};
