"use client";

import { Button } from "@evolve-storefront/ui/base/Button";
import { cn } from "@evolve-storefront/ui/helpers/styles";
import { Heart } from "lucide-react";
import type { ReactNode } from "react";
import type { MouseEventHandler } from "react";
import { forwardRef } from "react";

type BaseAddToWishlistButtonProps = {
	onClick: MouseEventHandler;
	className?: string;
	isHighlighted?: boolean;
};

/**
 * Base component not containing any hooks/contexts that might block rendering outside of a site context.s
 */
export const BaseAddToWishlistButton = forwardRef<
	HTMLButtonElement,
	BaseAddToWishlistButtonProps
>(({ className, onClick, isHighlighted }, ref) => (
	<Button
		variant="secondary"
		className={cn(
			"aspect-square w-12 rounded-full border-2 border-gray-200 bg-white p-2.5 hover:text-orange-500 focus:text-orange-500 md:p-2.5",
			isHighlighted && "fill-current text-orange-500",
			className,
		)}
		onClick={onClick}
		ref={ref}
		aria-label="Add to wishlist"
	>
		<Heart strokeWidth={2} />
	</Button>
));

BaseAddToWishlistButton.displayName = "BaseAddToWishlistButton";

type AddToWishlistButtonProps = {
	className?: string;
	variantSku?: string;
	withBackground?: boolean;
	onAddToWishlist?: () => void;
};

export const AddToWishlistButton = ({
	className,
	variantSku,
	onAddToWishlist,
}: AddToWishlistButtonProps): ReactNode => {
	const handleClick = () => {
		if (!variantSku) return; // TODO: Add warning here

		// TODO: Add logic for adding wishlist items once available here
		onAddToWishlist?.();
	};

	return (
		<BaseAddToWishlistButton
			className={className}
			onClick={(event) => {
				event?.preventDefault();
				handleClick();
			}}
			isHighlighted={false}
		/>
	);
};
