"use client";

export default function customImageLoader({ src, width, quality }) {
	if (src.startsWith("https://a.storyblok.com") && !src.endsWith(".svg")) {
		return `${src}/m/${width}x0/filters:quality(${quality || 80})`;
	}
	if (src.includes(".amplience.net") && !src.endsWith(".svg")) {
		return `${src}?w=${width}`;
	}
	if (src.includes("images.ctfassets.net") && !src.endsWith(".svg")) {
		return `${src}?w=${width}`;
	}
	if (
		src.startsWith("https://dam.evolve.labdigital.nl/") &&
		!src.endsWith(".svg")
	) {
		return `${src}?format=auto&width=${width}`;
	}
	if (
		src.startsWith("https://media.test.bluestonepim.com/") &&
		!src.endsWith(".svg")
	) {
		return `${src}?format=auto&width=${width}`;
	}

	if (src.startsWith("https://images.icecat.biz/") && !src.endsWith(".svg")) {
		return `${src}?format=auto&width=${width}`;
	}

	return src;
}
